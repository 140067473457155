/*!
 * Start Bootstrap - Landing Page Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

 body,
 html {
     width: 100%;
     height: 100%;
}
 
 body,
 h1,
 h3,
 h4,
 h5,
 h6 {
     font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
     font-weight: 700;
     color: grey;
 }

ul.none{
    list-style-type: none;
}

 a{
     color: #337ab7;
 }


 a:hover{
    color: #337ab7;
}


 /* Cool fade in transition stuff */

 div{
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
         animation: fadein 0.5s;
 }

 .dark-transition{
    
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
         -ms-animation: fadein 2s; /* Internet Explorer */
          -o-animation: fadein 2s; /* Opera < 12.1 */
             animation: fadein 2s;
     
 }

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* End cool fade in transition stuff */
 

 /* Dark Theme */



.body-dark{
     background-color: #000000;
     
 }

 .navbar-default-dark{
    padding-right: 15%;
    padding-left: 15%;
    margin-right: auto;
    margin-left: auto;
    background-color: #f8f8f8;
    border-color: #e7e7e7;
 }
 
 .navbar-default-dark .navbar-nav-dark > li > a:hover, .navbar-default-dark .navbar-nav-dark > li > a:focus {
       color: #ff0000;
       
 }

 .nav-items-dark{
    color: #777;
 }

 .nav-items-dark:hover {
    color: #ff0000;
 }
 .h2-dark{
   font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
   font-weight: 700;
   color: #ff0000;
 
 }

 .a-dark {
    color: #ff0000;
    text-decoration: none;
}

.a-dark:hover {
    color: #000000;
}
 
 .btn-default-dark {
    color: #ff0000;
    background-color: #000000;
    border-color: #ff0000 !important; 
}

.btn-default-dark:hover {
    color: #ff0000;
    background-color: #181818;
    border-color: #ff0000;
}

.btn-sec-homepage-dark:hover {
    color: #ff0000;
    background-color: #181818;
    border-color: #ff0000;
}


 .navbar-default-dark {
    background-color: #000000;
    border-color: #181818;
}

.dark-navbar-toggler{
    background-color: red;
}

 
 .intro-header-dark {
     padding-top: 50px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
     padding-bottom: 50px;
     text-align: center;
     color: #181818;
     background-size: cover;
 }
 
 .intro-message-dark > h1 {
     margin: 0;
     color: #ff0000;
     font-size: 5em;
 }
 
 .intro-divider-dark {
     width: 400px;
     border-top: 1px solid #181818;
     border-bottom: 1px solid rgba(0,0,0,0.2);
 }

 .site-icon-theme-dark{
    color: #ff0000;
}

 
.content-section-a-dark {
    padding: 50px 0;
    background-color: #181818;
}

.content-section-b-dark {
    padding: 50px 0;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

.section-heading-dark {
    margin-bottom: 30px;
}

.section-heading-spacer-dark {
    float: left;
    width: 200px;
    border-top: 3px solid #000000;
}

.banner-dark {
    padding: 100px 0;
    color: #181818;
    /*background: url(../img/banner-bg.jpg) no-repeat center center;*/
    background-size: cover;
}

.footer-dark {
    padding: 50px 0;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

.nav-footer-dark{

    background-color: #000000;
    border: none;

 }


.dark-card{
    background-color: #000000;
    border-color: red;

}

.dark-card-green{
    background-color: #000000;
    border-color: #28a745;

}

.dark-card-blue{
    background-color: #000000;
    border-color: #007bff;

}

.dark-card-yellow{
    background-color: #000000;
    border-color: #ffc107;

}

.dark-card-white{
    background-color: #000000;
    border-color: white;

}

.dark-btn-outline {
    color: red;
    border-color: red !important;
}

.dark-btn-outline-green {
    color:#28a745;
    border-color:#28a745 !important;
}

.dark-btn-outline-blue {
    color:#007bff;
    border-color:#007bff !important;
}

.dark-btn-outline-yellow{
    color:#ffc107;
    border-color:#ffc107 !important;
}

.dark-btn-outline-white{
    color:white;
    border-color:white !important;
}

.offsec-services-container {
    max-width: 1500px;
}


.dark-list-group-pricing-red{
    color: red;
    background-color: black;
    border-color: red;
}
 
.dark-list-group-pricing-green{
    color:#28a745;
    background-color: black;
    border-color:#28a745;
}

.dark-list-group-pricing-blue{
    color:#007bff;
    background-color: black;
    border-color:#007bff;
}

.dark-list-group-pricing-yellow{
    color:#ffc107;
    background-color: black;
    border-color:#ffc107;
}


.dark-list-group-pricing-white{
    color: white;
    background-color: black;
    border-color: white;
}


.pricing{
    color:#28a745;
}

.engagement-details{
    color:white;
}

.pentest-icon{
    color: red;
}

.app-pentest-icon{
    color: green;
}

.wireless-pentest-icon{
    color: #007bff;
}

.sec-awareness-icon{
    color: #ffc107;
}

.adversarial-icon{
    color: white;
}
.loading-icon-dark{
    color: red;
}

/* End of Dark Theme */




/* Dark Theme portfolio */

/* Basically just changes the accents to white */

.navbar-default-dark-portfolio .navbar-nav-dark-portfolio > li > a:hover, .navbar-default-dark-portfolio .navbar-nav-dark-portfolio > li > a:focus {
      color: #ffffff;
}

.nav-items-dark-portfolio{
   color: #777;
}

.nav-items-dark-portfolio:hover {
   color: #ffffff;
}

.dark-navbar-toggler-portfolio{
    background-color: white;
}

.h2-dark-portfolio{
  font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
  color:#ffffff;

}

.a-dark-portfolio {
   color: #ffffff;
   text-decoration: none;
}

.btn-default-dark-portfolio {
   color: #ffffff;
   background-color: #000000;
   border-color: #ffffff !important;
}

.btn-default-dark-portfolio:hover {
   color: #ffffff;
   background-color: #181818;
   border-color: #ffffff;
}

.intro-message-dark-portfolio > h1 {
    margin: 0;
    color: #ffffff;
    font-size: 5em;
}

.site-icon-theme-dark-portfolio{
   color: #ffffff;
}

.navbar-brand-dark-portfolio {
    margin-top: 5px !important;
}

.section-heading-dark-portfolio{
    color: white;
}


.portfolio-image-pos{
    margin-left: 67%;
    margin-top: -87%;
}

.portfolio-image-container{
    margin-top: 50%;
}

/* End of Dark portfolio Theme */

.navbar-light .navbar-nav .nav-link:hover {
       color: #3498db;
 }
 
 
 .navbar-brand {
    float: left;
    height: 50px;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: -5px;
    
}

.cust-nav-container {
    padding-right: 15%;
    padding-left: 15%;
    margin-right: auto;
    margin-left: auto;
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}
 
 
 h2{
   font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
   font-weight: 700;
   color: #3498db;
 
 }
 
 .topnav .navbar-default {
     font-size: 14px;
     padding-top: 2px;
     padding-bottom: 2px;
 }


 .btn{
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid
    transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
 }

 .btn-default {
    color: #333;
    background-color:#fff;
    border-color:#ccc;
}

 .btn-lg{
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
 }

 .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
}

.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}
 
 .lead {
     font-size: 18px;
     font-weight: 400;
 }
 
 .intro-header {
     padding-top: 50px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
     padding-bottom: 50px;
     text-align: center;
     color: #f8f8f8;
     background-size: cover;
 }
 
 .intro-message {
     position: relative;
 }
 
 .intro-message > h1 {
     margin: 0;
     color: #3498db;
     font-size: 5em;
 }

 .featured-project{
     margin-top: 50px;
 }


 .featured-project > h5{
    margin-top: 50px;
    color: #3498db;
}

 
 .intro-divider {
     width: 400px;
     border-top: 1px solid #f8f8f8;
     border-bottom: 1px solid rgba(0,0,0,0.2);
 }
 
 .intro-message > h3 {
   color: grey;
 }
 
.big-youtube-icon{
    font-size: 75px;
}

.big-github-icon{
    font-size: 75px;
}

.project-items-heading{
    color: grey;
}

.projects-in-progress{
    color: rgb(216, 202, 0);
}

.projects-done{
    color: green;
}

.projects-cancelled{
    color: red;
}

.big-terminal{
    font-size:25px;
}

.big-download{
    font-size:50px;
}

.list-decimal{
    list-style: decimal;
}

.span-top-25-px{
    padding-top: 25px;
}

.loading-icon{
    color: #6cbff7;
}

.download-list{
    padding-bottom: 20px;
}

.site-icon-theme{
    color: #3498db;
}

.scroll-wrapper {
	-webkit-overflow-scrolling: touch;
  	overflow-y: scroll;
}

.white-border{
    border-color: white;
}

.margin-bottom-5-perc{
    margin-bottom: 5%;
}

 @media(max-width:767px) {
     .intro-message {
         padding-bottom: 15%;
     }
 
     .intro-message > h1 {
         font-size: 3em;
     }
 
     ul.intro-social-buttons > li {
         display: block;
         margin-bottom: 20px;
         padding: 0;
     }
 
     ul.intro-social-buttons > li:last-child {
         margin-bottom: 0;
     }
 
     .intro-divider {
         width: 100%;
     }
 }
 
 .network-name {
     text-transform: uppercase;
     font-size: 14px;
     font-weight: 400;
     letter-spacing: 2px;
 }
 
 .content-section-a {
     padding: 50px 0;
     background-color: #f8f8f8;
 }
 
 .content-section-b {
     padding: 50px 0;
     border-top: 1px solid #e7e7e7;
     border-bottom: 1px solid #e7e7e7;
 }
 
 .section-heading {
     margin-bottom: 30px;
 }
 
 .section-heading-spacer {
     float: left;
     width: 200px;
     border-top: 3px solid #e7e7e7;
 }
 
 .banner {
     padding: 100px 0;
     color: #f8f8f8;
     /*background: url(../img/banner-bg.jpg) no-repeat center center;*/
     background-size: cover;
 }
 
 .banner h2 {
     margin: 0;
     text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
     font-size: 3em;
 }
 
 .banner ul {
     margin-bottom: 0;
 }
 
 .banner-social-buttons {
     float: right;
     margin-top: 0;
 }
 
 
 @media(max-width:1199px) {
     ul.banner-social-buttons {
         float: left;
         margin-top: 15px;
     }
     
 }
 
 @media(max-width:767px) {
     .banner h2 {
         margin: 0;
         text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
         font-size: 3em;
     }
 
     ul.banner-social-buttons > li {
         display: block;
         margin-bottom: 20px;
         padding: 0;
     }
 
     ul.banner-social-buttons > li:last-child {
         margin-bottom: 0;
     }
     .portfolio-image-container{
        margin-top: 50%;
        margin-left: -20%;
    }
    
 }

 .nav-footer{

    background-color: #ffffff;
    border: none;

 }
 footer {
     padding: 50px 0;
     border-top: 1px solid #e7e7e7;
     border-bottom: 1px solid #e7e7e7;
 }
 
 p.copyright {
     margin: 15px 0 0;
 }
